import {
  handleErrorWithSentry,
  replayIntegration,
  init,
  makeMultiplexedTransport,
  makeFetchTransport,
  moduleMetadataIntegration,
} from "@sentry/sveltekit";

const EXTRA_KEY = "ROUTE_TO";
const transport = makeMultiplexedTransport(makeFetchTransport, (args) => {
  const event = args.getEvent();
  if (event && event.extra && EXTRA_KEY in event.extra && Array.isArray(event.extra[EXTRA_KEY])) {
    return event.extra[EXTRA_KEY];
  }
  return [];
});

const dsn = "https://cf65befc3dc089f6b08e34ced83b11bd@o4505917412343808.ingest.sentry.io/4506196266188800";
const isDevelopment = ["-development.", "-staging.", "localhost:", "127.0.0.1"].some((part) =>
  window.location.host.includes(part)
);

init({
  dsn,
  environment: window.location.hostname,
  release: import.meta.env.VITE_BUILD_ID,

  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration(), moduleMetadataIntegration()],

  transport,
  beforeSend: (event) => {
    if (event?.tags?.dsn) {
      if (!event?.tags?.prod) {
        return null; // don't send event if not in prod
      }
      event.extra = {
        ...event.extra,
        [EXTRA_KEY]: [event?.tags],
      };
      return event;
    }

    if (event?.exception?.values?.[0]?.stacktrace?.frames) {
      const frames = event.exception.values[0].stacktrace.frames;
      // Find the last frame with module metadata containing a DSN
      const routeTo = frames
        .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
        .map((v) => v.module_metadata)
        .slice(-1); // using top frame only - you may want to customize this according to your needs

      if (routeTo.length) {
        if (!routeTo[0].prod) {
          return null; // don't send event if not in prod
        }
        event.extra = {
          ...event.extra,
          [EXTRA_KEY]: routeTo,
        };
      }
    }

    return event;
  },

  // Ignore specific errors
  ignoreErrors: [
    "Cannot read properties of undefined (reading 'moduleName')",
    /path ".*" not found/,
    ...(isDevelopment ? [/died in status LOADING_SOURCE_CODE/] : []),
  ],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
